import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ReportsContainer from '../../../Dashboards/Admin/components/Reports/main';
import Settlements from '../../../Dashboards/MasterTenant/components/Settlements/main';
import Dashboard from '../../../Dashboards/Dashboard';
import ClientSettlements from '../../../Dashboards/MasterTenant/components/Settlements/ClientSettlements/main';
// eslint-disable-next-line max-len
import LiquidityProviderSettlements from '../../../../containers/Dashboards/MasterTenant/components/Settlements/LiquidityProviderSettlements/main';
export default function OperationsRoot(props) {
    const { features } = props;
    const ordersEnabled = features && (features === null || features === void 0 ? void 0 : features.length) > 0 && (features === null || features === void 0 ? void 0 : features.includes('order.read'));
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: '/operations' },
            React.createElement(Redirect, { exact: true, to: 'operations/orders' })),
        React.createElement(Route, { path: '/operations/orders' },
            React.createElement(Dashboard, null)),
        React.createElement(Route, { path: '/operations/reports' },
            React.createElement(ReportsContainer, null)),
        React.createElement(Route, { path: '/operations/transfers' },
            React.createElement(Dashboard, null)),
        React.createElement(Route, { path: '/operations/customers-settlements' },
            React.createElement(Settlements, null)),
        React.createElement(Route, { path: '/operations/clients-settlements' },
            React.createElement(ClientSettlements, null)),
        React.createElement(Route, { path: '/operations/lp-settlements' },
            React.createElement(LiquidityProviderSettlements, null)),
        React.createElement(Route, { path: '/operations/schedulers' },
            React.createElement(Dashboard, null))));
}
