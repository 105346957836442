import React, { useEffect, useState } from 'react';
import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, TextField, useTheme, } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import ConfirmationDialog from '../../../../../../shared/components/confirmationDialog/ConfirmationDialog';
import { AmountCell, DateCell, TimeCell } from '../../../../../../shared/components/styled/tableCells';
import CopyValue from '../../../../../../shared/components/CopyValueComponent';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Amount } from '@shared/components/styled/amount';
import { format, parseISO } from 'date-fns';
import { visuallyHidden } from '@mui/utils';
const headCells = [
    {
        id: 'lp_provider',
        numeric: false,
        disablePadding: true,
        label: 'Liquidity provider',
        sortable: true,
        sortId: 'lp_provider',
    },
    {
        id: 'currency',
        numeric: true,
        disablePadding: true,
        label: 'Currency',
        sortable: true,
        sortId: 'currency',
    },
    {
        id: 'settlementCode',
        numeric: true,
        disablePadding: true,
        label: 'Code',
        sortable: false,
    },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: true,
        label: 'Created',
        sortable: true,
        sortId: 'created_at',
    },
    {
        id: 'dateFrom',
        numeric: false,
        disablePadding: true,
        label: 'From',
        sortable: false,
    },
    {
        id: 'dateTo',
        numeric: false,
        disablePadding: true,
        label: 'To',
        sortable: false,
    },
    {
        id: 'failedReason',
        numeric: false,
        disablePadding: true,
        label: 'Failed Reason',
        sortable: false,
    },
];
const useStyles = makeStyles(() => createStyles({
    customTableRow: {
        '& > tr:last-of-type > td': {
            borderBottom: 0,
        },
    },
}));
const LPSettlementsTableBody = ({ rows, type, executeSettlement, declineSettlement, manualExecute, onRequestSort, order, orderBy }) => {
    const [selecedSettlement, setSelectedSettlement] = useState('');
    const [selectedCommand, setSelectedCommand] = useState('');
    const theme = useTheme();
    const classes = useStyles();
    const [confirmDialog, setConfirmDialog] = useState('');
    const [collapsedRows, setCollapsedRows] = useState({});
    const [reference, setReference] = useState('');
    const [reasonDialogText, setReasonDialogText] = useState('');
    const onConfirm = (code, dialogName) => {
        setSelectedSettlement(code);
        setConfirmDialog(dialogName);
    };
    const onManualExecuteConfirm = (settlementCode, commandCode) => {
        setSelectedSettlement(settlementCode);
        setSelectedCommand(commandCode);
        setConfirmDialog('manual_execute');
    };
    const onConfirmClose = () => {
        setSelectedSettlement('');
        setConfirmDialog('');
    };
    const onConfirmExecute = () => {
        if (executeSettlement) {
            executeSettlement(selecedSettlement);
        }
        setConfirmDialog('');
        setSelectedSettlement('');
    };
    const onConfirmDecline = () => {
        if (declineSettlement) {
            declineSettlement(selecedSettlement);
        }
        setConfirmDialog('');
        setSelectedSettlement('');
    };
    const onConfirmManualExecute = () => {
        if (manualExecute) {
            manualExecute({ settlementCode: selecedSettlement, commandCode: selectedCommand, reference });
        }
        setConfirmDialog('');
        setSelectedSettlement('');
        setSelectedCommand('');
    };
    const handleCollapse = (code) => {
        const collapsed = Object.assign({}, collapsedRows);
        collapsed[code] = !collapsed[code];
        setCollapsedRows(collapsed);
    };
    const handleShowReason = (reason) => {
        if (reason) {
            setReasonDialogText(reason);
        }
    };
    useEffect(() => {
        const collapsed = Object.assign({}, collapsedRows);
        rows.forEach((row) => {
            collapsed[row.settlementCode] = true;
        });
        setCollapsedRows(collapsed);
    }, [rows]);
    const createSortHandler = (property) => () => {
        const isAsc = orderBy === property && order === 'asc';
        if (onRequestSort) {
            onRequestSort(isAsc ? 'desc' : 'asc', property);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, { sx: { minWidth: 750, backgroundColor: theme.palette.background.paper, tableLayout: 'auto' }, "aria-labelledby": 'tableTitle', size: 'small' },
            React.createElement(TableHead, null,
                React.createElement(TableRow, { sx: { background: theme.palette.secondary.light } },
                    headCells.map((headCell) => (React.createElement(React.Fragment, { key: headCell.id }, type !== 'failed' && headCell.id === 'failedReason' ? null : (React.createElement(TableCell, { sx: {
                            whiteSpace: 'nowrap',
                        } }, headCell.sortable && headCell.sortId ? (React.createElement(TableSortLabel, { active: orderBy === headCell.id, direction: orderBy === headCell.id ? order : 'asc', onClick: createSortHandler(headCell.sortId) },
                        headCell.label,
                        orderBy === headCell.id ? (React.createElement(Box, { component: 'span', sx: visuallyHidden }, order === 'desc' ? 'sorted descending' : 'sorted ascending')) : null)) : (headCell.label)))))),
                    type === 'created' ? (React.createElement(TableCell, { align: 'right', sx: {
                            whiteSpace: 'nowrap',
                        } }, "Actions")) : null)),
            React.createElement(TableBody, { className: classes.customTableRow }, rows.map((row, index) => {
                const labelId = `settlement-${index}`;
                return (React.createElement(React.Fragment, { key: labelId },
                    React.createElement(TableRow, { tabIndex: -1, sx: { backgroundColor: theme.palette.secondary.light } },
                        React.createElement(TableCell, { align: 'left' },
                            React.createElement(IconButton, { sx: { mr: 1 }, "aria-label": 'expand row', size: 'small', onClick: () => handleCollapse(row.settlementCode) }, collapsedRows[row.settlementCode] ? React.createElement(KeyboardArrowUp, null) : React.createElement(KeyboardArrowDown, null)),
                            row.lp_provider),
                        React.createElement(TableCell, { align: 'left' },
                            React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' }, gap: 1 }, row.currency.toUpperCase())),
                        React.createElement(TableCell, null,
                            React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' }, gap: 1 },
                                React.createElement(AmountCell, null, row.settlementCode),
                                React.createElement(CopyValue, { value: row.settlementCode }))),
                        React.createElement(TableCell, null, row.created_at || row.created_at !== undefined ? (React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' } },
                            React.createElement(DateCell, null, format(parseISO(row.created_at), 'dd-MM-yyyy')),
                            React.createElement(TimeCell, null, format(parseISO(row.created_at), 'HH:mm:ss')))) : ('N/A')),
                        React.createElement(TableCell, null, row.dateFrom || row.dateFrom !== undefined ? (React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' } },
                            React.createElement(DateCell, null, format(parseISO(row.dateFrom), 'dd-MM-yyyy')),
                            React.createElement(TimeCell, null, format(parseISO(row.dateFrom), 'HH:mm:ss')))) : ('N/A')),
                        React.createElement(TableCell, null, row.dateTo || row.dateTo !== undefined ? (React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' } },
                            React.createElement(DateCell, null, format(parseISO(row.dateTo), 'dd-MM-yyyy')),
                            React.createElement(TimeCell, null, format(parseISO(row.dateTo), 'HH:mm:ss')))) : ('N/A')),
                        type === 'failed' ? React.createElement(TableCell, { align: 'left' }, row.failedReason) : null,
                        type === 'created' ? (React.createElement(TableCell, { align: 'right' },
                            React.createElement(Box, { display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 1 },
                                React.createElement(Button, { size: 'small', variant: 'contained', onClick: (e) => {
                                        e.stopPropagation();
                                        onConfirm(row.settlementCode, 'execute');
                                    } }, "Execute"),
                                React.createElement(Button, { size: 'small', variant: 'contained', color: 'error', onClick: (e) => {
                                        e.stopPropagation();
                                        onConfirm(row.settlementCode, 'decline');
                                    } }, "Decline")))) : null),
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, { colSpan: 6, sx: { padding: 0, borderBottom: 'unset' } },
                            React.createElement(Collapse, { in: collapsedRows[row.settlementCode] },
                                React.createElement(Box, { sx: { px: 3, pb: 3 } },
                                    React.createElement(Box, { sx: { borderRadius: 1, backgroundColor: theme.palette.background.paper } },
                                        React.createElement(Table, { sx: { tableLayout: 'fixed' } },
                                            React.createElement(TableHead, null,
                                                React.createElement(TableRow, null,
                                                    React.createElement(TableCell, null, "Command code"),
                                                    React.createElement(TableCell, null, "Command type"),
                                                    row.status === 'created' ? React.createElement(TableCell, null, "Pool Wallet") : null,
                                                    React.createElement(TableCell, null, "Amount"),
                                                    React.createElement(TableCell, null, "State"),
                                                    row.status === 'failed' ? React.createElement(TableCell, null, "Failed Reason") : null,
                                                    row.status === 'pending' ? (React.createElement(TableCell, { sx: { textAlign: 'right' } }, "Actions")) : null)),
                                            React.createElement(TableBody, { className: classes.customTableRow }, row.commands.records.map((command) => (React.createElement(TableRow, { key: command.code },
                                                React.createElement(TableCell, null, command.code),
                                                React.createElement(TableCell, null, command.type),
                                                row.status === 'created' ? (React.createElement(TableCell, null,
                                                    React.createElement(Amount, null, row.poolBalance || 'N/A'))) : null,
                                                React.createElement(TableCell, null,
                                                    React.createElement(Amount, null, command.amount)),
                                                React.createElement(TableCell, null, command.state),
                                                command.state === 'pending' && row.status === 'pending' ? (React.createElement(TableCell, { sx: { textAlign: 'right' } }, command.type === 'lp_to_pool_wallet' ||
                                                    command.type === 'dlt_fiat_to_lp' ||
                                                    command.type === 'lp_fiat_to_dlt' ? (React.createElement(Button, { variant: 'contained', size: 'small', onClick: () => onManualExecuteConfirm(row.settlementCode, command.code) }, "Manual Execute")) : null)) : null,
                                                row.status === 'failed' ? (React.createElement(TableCell, null,
                                                    React.createElement(Button, { disabled: !command.failed_reason, onClick: () => handleShowReason(command.failed_reason) }, "Show Error"))) : null))))))))))));
            }))),
        React.createElement(ConfirmationDialog, { title: 'Execute Settlement', content: `Are you sure to exucute settlement with code: ${selecedSettlement}?`, open: confirmDialog === 'execute', onClose: onConfirmClose, onConfirm: onConfirmExecute }),
        React.createElement(ConfirmationDialog, { title: 'Decline Settlement', content: `Are you sure to decline settlement with code: ${selecedSettlement}?`, open: confirmDialog === 'decline', onClose: onConfirmClose, onConfirm: onConfirmDecline }),
        React.createElement(Dialog, { open: confirmDialog === 'manual_execute', onClose: () => setConfirmDialog(''), maxWidth: 'sm', fullWidth: true },
            React.createElement(DialogTitle, null, "Manually Execute"),
            React.createElement(DialogContent, null,
                React.createElement(Box, { py: 1 },
                    React.createElement(TextField, { required: true, label: 'Reference', size: 'small', onChange: (event) => setReference(event.target.value.trim()) }))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: () => setConfirmDialog(''), color: 'info' }, "Cancel"),
                React.createElement(Button, { onClick: onConfirmManualExecute, color: 'primary', variant: 'contained', disabled: !reference }, "Confirm"))),
        React.createElement(Dialog, { open: !!reasonDialogText, onClose: () => setReasonDialogText(''), maxWidth: 'md', fullWidth: true },
            React.createElement(DialogTitle, null, "Failed Reason"),
            React.createElement(DialogContent, null,
                React.createElement(Box, { py: 1 }, reasonDialogText)),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: () => setReasonDialogText(''), color: 'info' }, "Ok")))));
};
export default LPSettlementsTableBody;
